import React, { useState } from "react";
import { formattedDate } from "../../../helpers/dates";
import ParticipantModal from "../Modals/ParticipantModal";
import "../../../component/Carousel/carousel.css";
import styles from "../Detail/challenge.module.css";
import RoundImage from "../../../component/Image/RoundImage";
import { BANNER, PLACEHOLDER } from "../../../helpers/constant";
import { Link } from "react-router-dom";
import SlickCarousel from "../../../component/SlickCarousel/SlickCarousel";
import CoverImage from "../../../component/Image/CoverImage";

const CompletedCard = (props) => {
    const { completed } = props;
    const [showModal, setShowModal] = useState(false);
    const [participant, setParticipant] = useState();
    const [top, setTop] = useState();
    
    const showParticipant = (p, b) => {
        setParticipant(p.post);
        setTop(b);
        setShowModal(true);
    };

    return (
        <div className="row mt-2">
            <div className="col-lg-12 mb-3">
                <h4 className={`${styles["chal-title"]} h4 text-truncate text-capitalize`}>{completed.title}</h4>
                <div className={`${styles["chal-count-and-time"]}`}>
                    <span className={`${styles["chal-span"]}`}>
                        <i className="icofont-flame-torch" /> &nbsp;
                        {completed.challengers_count} Challengers
                    </span>
                    <span className={`${styles["time-span"]}`}>
                        <i className="icofont-clock-time" /> &nbsp;
                        {formattedDate(completed.registration_end)}
                    </span>
                </div>
            </div>
            <div className="col-lg-2">
                <div className={`${styles["course"]}`}>
                    <div className={`${styles["ribbon-container"]}`}>
                        <div className={`${styles["ribbon"]} ${styles["text-default"]}`}>Winner</div>
                    </div>

                    <div className={`course-meta pb-1 ${styles["pd-t-15"]}`}>
                        <div className={`${styles["post-by"]} w-100`}>
                            <figure>
                                <RoundImage size="91px" src={completed.winners?.find(c => c.rank === 1) ? completed.winners?.find(c => c.rank === 1).post.created_by.profile_pic || PLACEHOLDER : "" } alt="" />
                            </figure>
                            <div className={`course-cat w-100`}>
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#2d2d2d",
                                    }}
                                    className="mt-2"
                                >
                                    <Link to={`/profile/${completed.winners?.find(c => c.rank === 1)?.post.created_by.id}`}>
                                        {completed.winners?.find(c => c.rank === 1) ? completed.winners?.find(c => c.rank === 1).post.created_by.name : ""}
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="mt-1 mb-2">
                            <span
                                style={{
                                    color: "#92929e",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                }}
                            >
                                <i className="icofont-stamp" /> {completed.challengers[0].post.no_votes}
                            </span>
                        </div>

                        <Link to={`/challenges/${completed.id}/challengers`} className={`${styles["full-result-btn"]} mb-2 mt-3`}>
                            View Full Results
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-10">
                <section
                    className="regular slider suggested-clubs"
                    style={{
                        height: "250px !important",
                    }}
                >
                    <SlickCarousel customClass="slick-custom">
                        {completed.winners.map((a, index) => {
                            let iVal = "";
                            let iNum = "";
                            let is_badge = false;
                            switch (a.rank) {
                                case 1:
                                    iVal = "gold";
                                    iNum = 1;
                                    is_badge = true;
                                    break;
                                case 2:
                                    iVal = "silver";
                                    iNum = 2;
                                    is_badge = true;
                                    break;
                                case 3:
                                    iVal = "bronze";
                                    iNum = 3;
                                    is_badge = true;
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <div
                                    key={`${index}`}
                                    className={`slide-item mx-3 ${styles["challenger-item"]}`}
                                    onClick={() =>
                                        showParticipant(a, {
                                            num: a.rank,
                                            badge: iVal,
                                            is_badge,
                                        })
                                    }
                                    style={{
                                        flexGrow: "inherit",
                                        border: "1px solid rgba(76,87,102,0.1)",
                                        padding: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    {a.rank <= 3 && (
                                        <div className={`${styles["quiz-medal"]} ml-2`}>
                                            <div className={`${styles["quiz-medal__circle"]} ${styles["quiz-medal__circle--" + iVal]}`}>
                                                <span>{iNum}</span>
                                            </div>
                                            <div className={`${styles["quiz-medal__ribbon"]} ${styles["quiz-medal__ribbon--left"]}`} />
                                            <div className={`${styles["quiz-medal__ribbon"]} ${styles["quiz-medal__ribbon--right"]}`} />
                                        </div>
                                    )}
                                    <a
                                        style={{
                                            width: "60px",
                                            position: "absolute",
                                            right: "10px",
                                        }}
                                        href="#"
                                        className={`${styles["date"]} button mt-2`}
                                    >
                                        <i className="icofont-stamp" />
                                        &nbsp;{a.post.no_votes}
                                    </a>
                                    <CoverImage image className="img-thumbnail" bgrepeat="no-repeat" bgsize="cover" bgsrc={`${a.post.thumbnail || BANNER}`} width="100%" height="100%" />
                                    {/* <img src={a.post.thumbnail || PLACEHOLDER} alt="thumbnail" /> */}
                                </div>
                            );
                        })}
                    </SlickCarousel>
                    {participant && (
                        <ParticipantModal
                            className="full-width"
                            show={showModal}
                            participant={participant}
                            top={top}
                            onClose={() => {
                                setShowModal(false);
                            }}
                        />
                    )}
                </section>
            </div>
        </div>
    );
};

export default CompletedCard;
