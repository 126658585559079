import React, { useState } from "react";
import Video from "../../../component/Video/Video";
import { daysRemaining } from "../../../helpers/dates";
import { Link } from "react-router-dom";
import styles from "../Detail/challenge.module.css";
import { useSelector } from "react-redux";
import RoundImage from "../../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../../helpers/constant";

const ActiveCard = (props) => {
    const { challenge } = props;
    const auth_user = useSelector((state) => state.user.user);

    const renderLinkOrStatus = () => {
        if (challenge?.status == "submission") {
            return !challenge?.has_participated ? <Link to={`/challenge/${challenge.id}/accept`}>Participate</Link> : <>Participated</>;
        }

        if (challenge?.status == "voting") {
            return !challenge?.has_voted ? <Link to={`/challenges/${challenge.id}/challengers`}>Vote</Link> : <>Voted</>;
        }
    };

    const getLink = () => {
        if (challenge?.status == "submission") {
            return `/challenge/${challenge.id}/accept`;
        }

        if (challenge?.status == "voting") {
            return `/challenges/${challenge.id}/challengers`;
        }
    };

    return (
        <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
            <div className="blog-grid">
                <Video src={challenge.post.uri} style={{ height: "250px", borderRadius: "10px", objectFit: "cover" }} />

                <Link to={getLink()} title={challenge?.title}>
                    <div className="blog-meta mt-2">
                        <div className="blog-head">
                            <h4 style={{ width: "68%" }} className="blog-title text-capitalize text-truncate">
                                {challenge.title}
                            </h4>
                            {challenge?.created_by?.id !== auth_user.id && (
                                <span
                                    className="rate-result"
                                    style={{
                                        background: "#fec42d none repeat scroll 0 0",
                                        borderRadius: "30px",
                                        bottom: "15px",
                                        color: "#fff",
                                        fontSize: "11px",
                                        left: "15px",
                                        padding: "2px 10px",
                                        float: "right",
                                    }}
                                >
                                    {renderLinkOrStatus()}
                                </span>
                            )}
                            <div className={`${styles["post-by"]} post-by mt-2`}>
                                <RoundImage src={challenge?.post?.created_by?.profile_pic || PLACEHOLDER} size="20px" alt={challenge.post.created_by.name} />
                                <div className="course-cat">
                                    <span>By: {challenge.post.created_by.name}</span>
                                </div>
                            </div>
                            <a href="#" className={`${styles["date"]} ${styles["chl-remain-days"]} mt-3 date`}>
                                {challenge?.status == "submission" ? daysRemaining(challenge.registration_end) : daysRemaining(challenge.voting_end)}
                            </a>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ActiveCard;
