import React, { useCallback, useEffect, useState } from "react";
import ThemeLayout from "../../../layout/themeLayout";
import ActiveCard from "../Card/ActiveCard";
import CompletedCard from "../Card/CompletedCard";
import { useDispatch } from "react-redux";
import { challenges as challengesApi } from "../../../api/challenges";
import BackButton from "../../../component/BackButton/BackButton";

const Challenge = React.memo((props) => {
    const dispatch = useDispatch();

    // Get Active Challenges
    const [activeChallenges, setActiveChallenge] = useState([]);
    const [acPageNo, setAcPageNo] = useState(0);
    const [acPerPage, setAcPerPage] = useState(4);
    const [acLastPage, setAcLastPage] = useState(0);

    // Get Completed Challenges
    const [completedChallenges, setCompletedChallenge] = useState([]);
    const [ccPageNo, setCcPageNo] = useState(0);
    const [ccPerPage, setCcPerPage] = useState(2);
    const [ccLastPage, setCcLastPage] = useState(0);

    const getActiveChallenges = useCallback(
        (page_no = 0) => {
            dispatch(
                challengesApi({
                    param_path: "active",
                    isBackground: false,
                    params: {
                        per_page: acPerPage,
                        page_no: page_no + 1,
                    },
                }),
            )
                .then((response) => {
                    setActiveChallenge((prev) => prev.concat(response.data));
                    setAcLastPage(response.pagination_data.last_page);
                    setAcPageNo(response.pagination_data.current_page);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        [dispatch, acPerPage],
    );

    const getCompletedChallenges = useCallback(
        (page_no = 0) => {
            dispatch(
                challengesApi({
                    param_path: "completed",
                    isBackground: false,
                    params: {
                        per_page: ccPerPage,
                        page_no: page_no + 1,
                    },
                }),
            )
                .then((response) => {
                    setCompletedChallenge((prev) => prev.concat(response.data));
                    setCcLastPage(response.pagination_data.last_page);
                    setCcPageNo(response.pagination_data.current_page);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        [dispatch, ccPerPage],
    );

    useEffect(() => {
        getActiveChallenges();
        getCompletedChallenges();
    }, []);

    const renderActiveChallenges = () => {
        return activeChallenges?.map((ac) => {
            return <ActiveCard challenge={ac} key={ac.id} />;
        });
    };

    const renderCompletedChallenges = () => {
        return completedChallenges.map((cc) => {
            if (cc.challengers_count > 0) return <CompletedCard completed={cc} key={cc.id} />;
        });
    };

    return (
        <div>
            {/* page loader */}
            <ThemeLayout>
                {activeChallenges.length == 0 && completedChallenges.length == 0 && (
                    <section className="container-fluid">
                        <div className="text-center lead">No challenges</div>
                    </section>
                )}
                {activeChallenges.length > 0 && (
                    <section>
                        <div className="gap">
                            <div className="container-fluid">
                                <BackButton />
                                <div className="row remove-ext30">
                                    <div className="col-lg-12">
                                        <div className="title">
                                            <h1>Active Challenges</h1>
                                            <p>Our recent active challenges</p>
                                        </div>
                                    </div>
                                    {renderActiveChallenges()}

                                    {acLastPage != acPageNo && (
                                        <div style={{ margin: "auto" }} className="col-lg-2 mt-3">
                                            <div className="loadmore">
                                                <a onClick={() => getActiveChallenges(acPageNo)} data-ripple>
                                                    Load More..
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <br clear="all" />
                {completedChallenges.length > 0 && (
                    <section className="mt-3">
                        <div className="gap">
                            <div className="container-fluid">
                                <div className="row remove-ext30">
                                    <div className="col-lg-12">
                                        <div className="title">
                                            <h1>Past Challenges</h1>
                                        </div>
                                    </div>
                                </div>
                                {completedChallenges && renderCompletedChallenges()}
                                {/* <CompletedCard /> */}
                                {ccLastPage != ccPageNo && (
                                    <div className="row">
                                        <div style={{ margin: "auto" }} className="col-lg-2">
                                            <div className="loadmore">
                                                <a onClick={() => getCompletedChallenges(ccPageNo)} data-ripple>
                                                    Load More..
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                )}
            </ThemeLayout>
        </div>
    );
});

export default Challenge;
