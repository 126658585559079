import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../component/Button/Button";
import Video from "../../../component/Video/Video";
import { fromNow } from "../../../helpers/dates";
import ChallengerPost from "../ChallengerPost/ChallengerPost";
import styles from "./participant-modal.module.css";

const ParticipantModal = (props) => {
    const handleClose = props.onClose;
    const top = props.top;

    const [post, setPost] = useState(props.participant);

    const addComment = (post) => {
        setPost(post);
    };

    return (
        <>
            {post && (
                <Modal className={`${styles["full-width"]} p-3`} size="xl" show={props.show} onHide={handleClose}>
                    <Modal.Header className="p-2">
                        <Button className="close" htmlType="link" onClick={handleClose}>
                            x
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row merged">
                            <ChallengerPost post={props.participant} top={props.top} updatePost={addComment} />
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default ParticipantModal;
