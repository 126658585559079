import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RoundImage from "../../../component/Image/RoundImage";
import ShareModal from "../../../component/ShareModal/ShareModal";
import Video from "../../../component/Video/Video";
import { posts as postsApi } from "../../../api/posts";
import { comments } from "../../../api/comment";
import { PLACEHOLDER } from "../../../helpers/constant";
import { Link } from "react-router-dom";
import PostComments from "../../../Widgets/Posts/PostComments";
import styles from "../Detail/challenge.module.css";
import PostStats from "../../../Widgets/Posts/PostStats";
import PostReaction from "../../../Widgets/Posts/PostReaction";

const ChallengerPost = (props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [commentText, setCommentText] = useState("");
    const likeBtn = useRef();
    const submittingCommentRef = useRef();
    const auth_user = useSelector((store) => store.user.user);
    const [showComments, setShowComments] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const { top } = props;
    const [comments, setComments] = useState(props.post?.comments || []);

    const formRef = useRef();
    const commentCollapse = useRef();

    const onComment = async (e) => {
        e.preventDefault();
        submittingCommentRef.current?.setLoader(true);
        try {
            const params = {
                method: "POST",
                params: {
                    post_id: props.post?.id,
                    comment: commentText,
                },
            };
            const response = await dispatch(comments(params));
            if (response.success) {
                props.updatePost(response.data);
                setCommentText("");
            }
        } catch (err) {
            console.log(err);
        }
        submittingCommentRef.current?.setLoader(false);
    };

    const onLike = async (e) => {
        e.preventDefault();
        likeBtn.current?.setLoader(true);
        try {
            const params = {
                method: "POST",
                param_path: `${props.post.id}/like`,
            };

            const response = await dispatch(postsApi(params));

            if (response.success) {
                props.updatePost(response.data);
            }
        } catch (err) {
            console.log(err);
        }

        likeBtn.current?.setLoader(false);
    };

    const navigateToDetail = (e) => {
        e.stopPropagation();
        history.push(`/post/${props.post?.id}`);
    };

    const toggleComments = () => {
        setShowComments((p) => !p);
    };

    return (
        <div className="py-0 pl-0 col-lg-12">
            <ShareModal post={props.post} show={showShareModal} handleClose={() => setShowShareModal(false)} />
            <div className="row">
                <div className="col-lg-9">
                    <div className="pop-image">
                        <div className="pop-item">
                            <Video postId={props.post?.id} src={props.post?.uri} style={{ width: "100%", height: '800px' }} hasWatched={props.post?.has_viewed} fullScreen />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 px-0 py-2 commentbar">
                    <div className="user-post mt-3 ml-2">
                        <div className="friend-info">
                            <RoundImage size="50px" src={props.post?.created_by?.profile_pic || PLACEHOLDER} alt={props.post?.created_by?.name} />
                            <div className="friend-name">
                                <ins>
                                    <Link to={`/profile/${props.post?.created_by?.id}`}>{props.post?.created_by?.name}</Link>
                                </ins>
                                <span>{top?.badge == "gold" ? "Winner" : top.badge == "silver" ? "Runner-up" : ""} </span>
                            </div>
                            {top.is_badge && (
                                <div className={`${styles["quiz-medal"]} ${styles["modal-badge"]} pr-5`}>
                                    <div className={`${styles["quiz-medal__circle"]} ${styles["quiz-medal__circle--" + top.badge]}`}>
                                        <span>{top.num}</span>
                                    </div>
                                    <div className={`${styles["quiz-medal__ribbon"]} ${styles["quiz-medal__ribbon--left"]}`} />
                                    <div className={`${styles["quiz-medal__ribbon"]} ${styles["quiz-medal__ribbon--right"]}`} />
                                </div>
                            )}
                            <div className="post-meta">
                                <p onClick={navigateToDetail} style={{ cursor: "pointer" }}>
                                    {props.post?.content}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ml-2 d-flex">
                        <Button
                            onClick={() => setShowShareModal((p) => !p)}
                            className="text-center text-muted pl-0 pr-1"
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            <span>
                                <i className="icofont-share mr-1"></i>
                                {props.post.no_shares}
                            </span>
                        </Button>
                        <PostStats post={props.post} />
                    </div>
                    <PostReaction post={props.post} updatePost={props.updatePost} />
                    <PostComments comments={props.post?.comments} show={true} updatePost={props.updatePost} post_id={props.post?.id} no_comments={props.post?.no_comments} />
                </div>
            </div>
        </div>
    );
};

export default ChallengerPost;
